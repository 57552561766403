/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { LoginForm } from '../../components';
import '../../assets/css/login.css';
import { submitLoginDetails, showLoader, submitAuth0LoginDetails } from '../../actions';
import history from '../../helpers/history';
import { ROUTES_PATH } from '../../constant';
import { openDatabase } from '../../helpers/dbStorage';
import { Loader } from '../../components/shared/TileLoader';
import { debounce } from 'lodash';

// eslint-disable-next-line no-undef
const url = process.env.REACT_APP_WEB_BASE_URL;

const Login = (props) => {
  const { user, isAuthenticated } = useAuth0();

  const loginCallback = () => {
    const userObj = {
      email: user?.email || user?.sub.split('|')[2].toLowerCase(),
    };
    props.submitAuth0LoginDetails(userObj);
    debounce(() => {
      window.location.href = ROUTES_PATH.HOME;
    }, 5000);
  };

  useEffect(() => {
    try {
      openDatabase();
      document.getElementsByClassName('login-wrapper')[0].onclick = () => handleBlur();
      document.getElementsByClassName('login-center-layout')[0].onclick = (event) => event.stopPropagation();
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  // handle enter keypress event
  const onReturnKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (event.target.name === 'username') {
        let nextField = document.getElementsByName('password')[0];
        nextField && nextField.focus();
      } else {
        handleBlur();
      }
    }
  };

  const handleBlur = () => window.cordova && window.cordova.platformId === 'ios' && window.Keyboard.hide();

  const _goToResetPassword = () => {
    history.push(ROUTES_PATH.RESETPASSWORD);
  };

  useEffect(() => {
    if (user?.email || user?.sub.split('|')[2]) {
      loginCallback();
    }
  }, [user]);

  return (
    <div className="login-wrapper keyboard">
      {(isAuthenticated || props.loading) && <Loader />}
      {!user?.email && (
        <div className="login-center-layout">
          <h1>
            <a href={url} target="_blank" rel="noreferrer"></a>
          </h1>

          <p className="login-para">
            Welcome to <span className="product-name">PipelinePlus</span>.
            <br />
            You're one login away from business development 2.0.
          </p>
          <div className="bubble">
            <p>Sign in to your PipelinePlus account:</p>
            <LoginForm
              onReturnKeyPress={onReturnKeyPress}
              handleBlur={handleBlur}
              onSubmit={props.submitLoginDetails}
            />
            <p>
              This app requires a paid PipelinePlus account. If you don't have a corporate PipelinePlus account, ask
              your administrator about implementing PipelinePlus at your firm or click{' '}
              <a href="https://www.pipelineplus.com/pricing-and-plans/">here to view pricing options.</a>
            </p>
            <br />
            <h4>
              For firms with single sign on (SSO) enabled, <Link to={ROUTES_PATH.SSO}>click here </Link>to sign in.
            </h4>
          </div>
          <p>
            <Link className="login-lost-pwd" to={ROUTES_PATH.RESETPASSWORD}>
              Lost your password?
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};
// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  submitLoginDetails: (data) => {
    dispatch(showLoader());
    return dispatch(submitLoginDetails(data));
  },
  submitAuth0LoginDetails: (data) => {
    dispatch(showLoader());
    return dispatch(submitAuth0LoginDetails(data));
  },
});
// map state to props
const mapStateToProps = (state) => ({
  ...state.session,
  loading: state.loader,
  attention: state.attention,
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
